import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post, get } from "../../api"
import { CATEGORIES_ADD, CATEGORIES_DELETE, CATEGORIES_DETAILS, CATEGORIES_EDIT, CATEGORIES_LIST, CATEGORY_AND_ADVERTISEMENT, CATEGORY_WISE_ADVERTISEMENT, ADVERTISEMENT_WITHOUT_CATEGORIES } from "../../constant"

//List
export const actionCategories = createAsyncThunk('appCategoriesSlice/actionCategories', async (params, thunkAPI) => {
    return await post(CATEGORIES_LIST, params, thunkAPI)
})
//categoryAndadvertisment
export const actionCategoryAndAdvertisment = createAsyncThunk('appCategoriesSlice/actionCategoryAndAdvertisment', async (params, thunkAPI) => {
    return await post(CATEGORY_AND_ADVERTISEMENT, params, thunkAPI)
})
//categoryWiseadvertisment
export const actionCategoryWiseAdvertisment = createAsyncThunk('appCategoriesSlice/actionCategoryWiseAdvertisment', async (params, thunkAPI) => {
    return await post(CATEGORY_WISE_ADVERTISEMENT, params, thunkAPI)
})
// //Add
export const actionAddCategories = createAsyncThunk('appCategoriesSlice/actionAddCategories', async (params, thunkAPI) => {
    return await post(CATEGORIES_ADD, params, thunkAPI)
})
//Details
export const actionGetCategories = createAsyncThunk('appCategoriesSlice/actionGetCategories', async (params, thunkAPI) => {
    return await post(CATEGORIES_DETAILS, params, thunkAPI)
})
//Edit
export const actionEditCategories = createAsyncThunk('appCategoriesSlice/actionEditCategories', async (params, thunkAPI) => {
    return await post(CATEGORIES_EDIT, params, thunkAPI)
})
// //Delete
export const actionDeleteCategories = createAsyncThunk('appCategoriesSlice/actionDeleteCategories', async (params, thunkAPI) => {
    return await post(CATEGORIES_DELETE, params, thunkAPI)
})

// Ads without category
export const actionAdvertisementWithoutCategory = createAsyncThunk('appCategoriesSlice/actionAdvertisementWithoutCategory', async (params, thunkAPI) => {
    return await get(ADVERTISEMENT_WITHOUT_CATEGORIES, params, thunkAPI)
})

export const appCategoriesSlice = createSlice({
    name: 'appCategoriesSlice',
    initialState: {
        loadingCategories: false,
        categories: null,

        loadingAddCategories: false,
        addCategories: null,

        loadingGetCategories: false,
        getCategories: null,

        loadingEditCategories: false,
        editCategories: null,

        loadingDeleteCategories: false,
        categoriesDelete: null,

        loadingCategoryAndAdvertisement: false,
        categoryAndAdvertisement: null,

        loadingCategoryWiseAdvertisement: false,
        categoryWiseAdvertisement: null,

        loadingAdvertisementWithoutCategory: false,
        advertisementWithoutCategory: null,

    },
    reducers: {
        resetCategoriesResponse: (state) => {
            state.categories = null
        },

        resetAddCategoriesResponse: (state) => {
            state.addCategories = null
        },
        resetGetCategoriesResponse: (state) => {
            state.getCategories = null
        },

        resetEditCategoriesResponse: (state) => {
            state.editCategories = null
        },

        resetDeleteCategoriesResponse: (state) => {
            state.categoriesDelete = null
        },
        resetCategoryAndAdvertisementResponse: (state) => {
            state.categoryAndAdvertisement = null
        },
        resetCategoryWiseadvertisementResponse: (state) => {
            state.categoryWiseAdvertisement = null
        },
        resetAdvertisementWithoutCategoryResponse: (state) => {
            state.advertisementWithoutCategory = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionCategories.pending, (state) => {
                state.loadingCategories = true
            })

            .addCase(actionCategories.fulfilled, (state, action) => {
                state.loadingCategories = false
                state.categories = action.payload
            })
            .addCase(actionCategories.rejected, (state) => {
                state.loadingCategories = false
            })

            //Add
            .addCase(actionAddCategories.pending, (state) => {
                state.loadingAddCategories = true
            })
            .addCase(actionAddCategories.fulfilled, (state, action) => {
                state.loadingAddCategories = false
                state.addCategories = action.payload
            })
            .addCase(actionAddCategories.rejected, (state) => {
                state.loadingAddCategories = false
            })

            // Details
            .addCase(actionGetCategories.pending, (state) => {
                state.loadingGetCategories = true
            })
            .addCase(actionGetCategories.fulfilled, (state, action) => {
                state.loadingGetCategories = false
                state.getCategories = action.payload
            })
            .addCase(actionGetCategories.rejected, (state) => {
                state.loadingGetCategories = false
            })

            // Edit
            .addCase(actionEditCategories.pending, (state) => {
                state.loadingEditCategories = true
            })
            .addCase(actionEditCategories.fulfilled, (state, action) => {
                state.loadingEditCategories = false
                state.editCategories = action.payload
            })
            .addCase(actionEditCategories.rejected, (state) => {
                state.loadingEditCategories = false
            })

            //Delete
            .addCase(actionDeleteCategories.pending, (state) => {
                state.loadingDeleteCategories = true
            })
            .addCase(actionDeleteCategories.fulfilled, (state, action) => {
                state.loadingDeleteCategories = false
                state.categoriesDelete = action.payload
            })
            .addCase(actionDeleteCategories.rejected, (state) => {
                state.loadingDeleteCategories = false
            })

            //category and advertisement
            .addCase(actionCategoryAndAdvertisment.pending, (state) => {
                state.loadingCategoryAndAdvertisement = true
            })
            .addCase(actionCategoryAndAdvertisment.fulfilled, (state, action) => {
                state.loadingCategoryAndAdvertisement = false
                state.categoryAndAdvertisement = action.payload
            })
            .addCase(actionCategoryAndAdvertisment.rejected, (state) => {
                state.loadingCategoryAndAdvertisement = false
            })

            //category wise advertisement
            .addCase(actionCategoryWiseAdvertisment.pending, (state) => {
                state.loadingCategoryWiseAdvertisement = true
            })
            .addCase(actionCategoryWiseAdvertisment.fulfilled, (state, action) => {
                state.loadingCategoryWiseAdvertisement = false
                state.categoryWiseAdvertisement = action.payload
            })
            .addCase(actionCategoryWiseAdvertisment.rejected, (state) => {
                state.loadingCategoryWiseAdvertisement = false
            })

            //advertisement without category
            .addCase(actionAdvertisementWithoutCategory.pending, (state) => {
                state.loadingAdvertisementWithoutCategory = true
            })
            .addCase(actionAdvertisementWithoutCategory.fulfilled, (state, action) => {
                state.loadingAdvertisementWithoutCategory = false
                state.advertisementWithoutCategory = action.payload
            })
            .addCase(actionAdvertisementWithoutCategory.rejected, (state) => {
                state.loadingAdvertisementWithoutCategory = false
            })

    }
})
export const { resetCategoriesResponse,
    resetAddCategoriesResponse,
    resetGetCategoriesResponse,
    resetEditCategoriesResponse,
    resetDeleteCategoriesResponse,
    resetCategoryAndAdvertisementResponse,
    resetCategoryWiseadvertisementResponse,
    resetAdvertisementWithoutCategoryResponse
} = appCategoriesSlice.actions

export default appCategoriesSlice.reducer
