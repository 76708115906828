import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post } from "../../api"
import { REGION_ADD, REGION_DELETE, REGION_DETAILS, REGION_EDIT, REGION_LIST } from "../../constant"

//List
export const actionRegion = createAsyncThunk('appRegionSlice/actionRegion', async (params, thunkAPI) => {
    return await post(REGION_LIST, params, thunkAPI)
})
// //Add
export const actionAddRegion = createAsyncThunk('appRegionSlice/actionAddRegion', async (params, thunkAPI) => {
    return await post(REGION_ADD, params, thunkAPI)
})
//Details
export const actionGetRegion = createAsyncThunk('appRegionSlice/actionGetRegion', async (params, thunkAPI) => {
    return await post(REGION_DETAILS, params, thunkAPI)
})
//Edit
export const actionEditRegion = createAsyncThunk('appRegionSlice/actionEditRegion', async (params, thunkAPI) => {
    return await post(REGION_EDIT, params, thunkAPI)
})
// //Delete
export const actionDeleteRegion = createAsyncThunk('appRegionSlice/actionDeleteRegion', async (params, thunkAPI) => {
    return await post(REGION_DELETE, params, thunkAPI)
})
export const appRegionSlice = createSlice({
    name: 'appRegionSlice',
    initialState: {
        loadingRegion: false,
        region: null,

        loadingAddRegion: false,
        addRegion: null,

        loadingGetRegion: false,
        getRegion: null,

        loadingEditRegion: false,
        editRegion: null,

        loadingDeleteRegion: false,
        deleteRegion: null
    },
    reducers: {
        resetRegionResponse: (state) => {
            state.region = null
        },

        resetAddRegionResponse: (state) => {
            state.addRegion = null
        },
        resetGetRegionResponse: (state) => {
            state.getRegion = null
        },

        resetEditRegionResponse: (state) => {
            state.editRegion = null
        },

        resetDeleteRegionResponse: (state) => {
            state.deleteRegion = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionRegion.pending, (state) => {
                state.loadingRegion = true
            })

            .addCase(actionRegion.fulfilled, (state, action) => {
                state.loadingRegion = false
                state.region = action.payload
            })
            .addCase(actionRegion.rejected, (state) => {
                state.loadingRegion = false
            })

            //Add
            .addCase(actionAddRegion.pending, (state) => {
                state.loadingAddRegion = true
            })
            .addCase(actionAddRegion.fulfilled, (state, action) => {
                state.loadingAddRegion = false
                state.addRegion = action.payload
            })
            .addCase(actionAddRegion.rejected, (state) => {
                state.loadingAddRegion = false
            })

            // Details
            .addCase(actionGetRegion.pending, (state) => {
                state.loadingGetRegion = true
            })
            .addCase(actionGetRegion.fulfilled, (state, action) => {
                state.loadingGetRegion = false
                state.getRegion = action.payload
            })
            .addCase(actionGetRegion.rejected, (state) => {
                state.loadingGetRegion = false
            })

            // Edit
            .addCase(actionEditRegion.pending, (state) => {
                state.loadingEditRegion = true
            })
            .addCase(actionEditRegion.fulfilled, (state, action) => {
                state.loadingEditRegion = false
                state.editRegion = action.payload
            })
            .addCase(actionEditRegion.rejected, (state) => {
                state.loadingEditRegion = false
            })

            //Delete
            .addCase(actionDeleteRegion.pending, (state) => {
                state.loadingDeleteRegion = true
            })
            .addCase(actionDeleteRegion.fulfilled, (state, action) => {
                state.loadingDeleteRegion = false
                state.deleteRegion = action.payload
            })
            .addCase(actionDeleteRegion.rejected, (state) => {
                state.loadingDeleteRegion = false
            })

    }
})
export const { resetRegionResponse,
    resetAddRegionResponse,
    resetGetRegionResponse,
    resetEditRegionResponse,
    resetDeleteRegionResponse
} = appRegionSlice.actions

export default appRegionSlice.reducer