import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post } from "../../api"
import { LOCATION_MASTER_ADD, LOCATION_MASTER_DELETE, LOCATION_MASTER_DETAILS, LOCATION_MASTER_EDIT, LOCATION_MASTER_LIST } from "../../constant"

//List
export const actionLocationMaster = createAsyncThunk('appLocationMasterSlice/actionLocationMaster', async (params, thunkAPI) => {
    return await post(LOCATION_MASTER_LIST, params, thunkAPI)
})
// //Add
export const actionAddLocationMaster = createAsyncThunk('appLocationMasterSlice/actionAddLocationMaster', async (params, thunkAPI) => {
    return await post(LOCATION_MASTER_ADD, params, thunkAPI)
})
//Details
export const actionGetLocationMaster = createAsyncThunk('appLocationMasterSlice/actionGetLocationMaster', async (params, thunkAPI) => {
    return await post(LOCATION_MASTER_DETAILS, params, thunkAPI)
})
//Edit
export const actionEditLocationMaster = createAsyncThunk('appLocationMasterSlice/actionEditLocationMaster', async (params, thunkAPI) => {
    return await post(LOCATION_MASTER_EDIT, params, thunkAPI)
})
// //Delete
export const actionDeleteLocationMaster = createAsyncThunk('appLocationMasterSlice/actionDeleteLocationMaster', async (params, thunkAPI) => {
    return await post(LOCATION_MASTER_DELETE, params, thunkAPI)
})
export const appLocationMasterSlice = createSlice({
    name: 'appLocationMasterSlice',
    initialState: {
        loadingLocationMaster: false,
        locationMaster: null,

        loadingAddLocationMaster: false,
        addLocationMaster: null,

        loadingGetLocationMaster:false,
        getLocationMaster:null,

        loadingEditLocationMaster: false,
        editLocationMaster: null,

        loadingDeleteLocationMaster: false,
        locationMasterDelete: null
    },
    reducers: {
        resetLocationMasterResponse: (state) => {
            state.locationMaster = null
        },

        resetAddLocationMasterResponse: (state) => {
            state.addLocationMaster = null
        },
        resetGetLocationMasterResponse: (state) => {
            state.getLocationMaster = null
        },

        resetEditLocationMasterResponse: (state) => {
            state.editLocationMaster = null
        },

        resetDeleteLocationMasterResponse: (state) => {
            state.locationMasterDelete = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionLocationMaster.pending, (state) => {
                state.loadingLocationMaster = true
            })

            .addCase(actionLocationMaster.fulfilled, (state, action) => {
                state.loadingLocationMaster = false
                state.locationMaster = action.payload
            })
            .addCase(actionLocationMaster.rejected, (state) => {
                state.loadingLocationMaster = false
            })

            //Add
            .addCase(actionAddLocationMaster.pending, (state) => {
                state.loadingAddLocationMaster = true
            })
            .addCase(actionAddLocationMaster.fulfilled, (state, action) => {
                state.loadingAddLocationMaster = false
                state.addLocationMaster = action.payload
            })
            .addCase(actionAddLocationMaster.rejected, (state) => {
                state.loadingAddLocationMaster = false
            })

             // Details
             .addCase(actionGetLocationMaster.pending, (state) => {
                state.loadingGetLocationMaster = true
            })
            .addCase(actionGetLocationMaster.fulfilled, (state, action) => {
                state.loadingGetLocationMaster = false
                state.getLocationMaster = action.payload
            })
            .addCase(actionGetLocationMaster.rejected, (state) => {
                state.loadingGetLocationMaster = false
            })

             // Edit
             .addCase(actionEditLocationMaster.pending, (state) => {
                state.loadingEditLocationMaster = true
            })
            .addCase(actionEditLocationMaster.fulfilled, (state, action) => {
                state.loadingEditLocationMaster = false
                state.editLocationMaster = action.payload
            })
            .addCase(actionEditLocationMaster.rejected, (state) => {
                state.loadingEditLocationMaster = false
            })

            //Delete
            .addCase(actionDeleteLocationMaster.pending, (state) => {
                state.loadingDeleteLocationMaster = true
            })
            .addCase(actionDeleteLocationMaster.fulfilled, (state, action) => {
                state.loadingDeleteLocationMaster = false
                state.locationMasterDelete = action.payload
            })
            .addCase(actionDeleteLocationMaster.rejected, (state) => {
                state.loadingDeleteLocationMaster = false
            })
        }
})
export const { resetLocationMasterResponse,
    resetAddLocationMasterResponse,
    resetGetLocationMasterResponse,
    resetEditLocationMasterResponse,
    resetDeleteLocationMasterResponse
} = appLocationMasterSlice.actions

export default appLocationMasterSlice.reducer