import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { multipart, post } from "../../api"
import { ADVERTISEMENT_ADD, ADVERTISEMENT_DELETE, ADVERTISEMENT_DETAILS, ADVERTISEMENT_EDIT, ADVERTISEMENT_LIST } from "../../constant"

//List
export const actionAdvertisement = createAsyncThunk('appAdvertisementSlice/actionAdvertisement', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_LIST, params, thunkAPI)
})
// //Add
export const actionAddAdvertisement = createAsyncThunk('appAdvertisementSlice/actionAddAdvertisement', async (params, thunkAPI) => {
    return await multipart(ADVERTISEMENT_ADD, params, thunkAPI)
})
//Details
export const actionGetAdvertisement = createAsyncThunk('appAdvertisementSlice/actionGetAdvertisement', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_DETAILS, params, thunkAPI)
})
//Edit
export const actionEditAdvertisement = createAsyncThunk('appAdvertisementSlice/actionEditAdvertisement', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_EDIT, params, thunkAPI)
})
// //Delete
export const actionDeleteAdvertisement = createAsyncThunk('appAdvertisementSlice/actionDeleteAdvertisement', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_DELETE, params, thunkAPI)
})

export const appAdvertisementSlice = createSlice({
    name: 'appAdvertisementSlice',
    initialState: {
        loadingAdvertisement: false,
        advertisement: null,

        loadingAddAdvertisement: false,
        addAdvertisement: null,

        loadingGetAdvertisement:false,
        getAdvertisement:null,

        loadingEditAdvertisement: false,
        editAdvertisement: null,

        loadingDeleteAdvertisement: false,
        advertisementDelete: null
    },
    reducers: {
        resetAdvertisementResponse: (state) => {
            state.advertisement = null
        },

        resetAddAdvertisementResponse: (state) => {
            state.addAdvertisement = null
        },
        resetGetAdvertisementResponse: (state) => {
            state.getAdvertisement = null
        },

        resetEditAdvertisementResponse: (state) => {
            state.editAdvertisement = null
        },

        resetDeleteAdvertisementResponse: (state) => {
            state.advertisementDelete = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionAdvertisement.pending, (state) => {
                state.loadingAdvertisement = true
            })

            .addCase(actionAdvertisement.fulfilled, (state, action) => {
                state.loadingAdvertisement = false
                state.advertisement = action.payload
            })
            .addCase(actionAdvertisement.rejected, (state) => {
                state.loadingAdvertisement = false
            })

            //Add
            .addCase(actionAddAdvertisement.pending, (state) => {
                state.loadingAddAdvertisement = true
            })
            .addCase(actionAddAdvertisement.fulfilled, (state, action) => {
                state.loadingAddAdvertisement = false
                state.addAdvertisement = action.payload
            })
            .addCase(actionAddAdvertisement.rejected, (state) => {
                state.loadingAddAdvertisement = false
            })

            // Details
            .addCase(actionGetAdvertisement.pending, (state) => {
                state.loadingGetAdvertisement = true
            })
            .addCase(actionGetAdvertisement.fulfilled, (state, action) => {
                state.loadingGetAdvertisement = false
                state.getAdvertisement = action.payload
            })
            .addCase(actionGetAdvertisement.rejected, (state) => {
                state.loadingGetAdvertisement = false
            })

            // Edit
            .addCase(actionEditAdvertisement.pending, (state) => {
                state.loadingEditAdvertisement = true
            })
            .addCase(actionEditAdvertisement.fulfilled, (state, action) => {
                state.loadingEditAdvertisement = false
                state.editAdvertisement = action.payload
            })
            .addCase(actionEditAdvertisement.rejected, (state) => {
                state.loadingEditAdvertisement = false
            })

            //Delete
            .addCase(actionDeleteAdvertisement.pending, (state) => {
                state.loadingDeleteAdvertisement = true
            })
            .addCase(actionDeleteAdvertisement.fulfilled, (state, action) => {
                state.loadingDeleteAdvertisement = false
                state.advertisementDelete = action.payload
            })
            .addCase(actionDeleteAdvertisement.rejected, (state) => {
                state.loadingDeleteAdvertisement = false
            })
    }
})
export const { resetAdvertisementResponse,
    resetAddAdvertisementResponse,
    resetGetAdvertisementResponse,
    resetEditAdvertisementResponse,
    resetDeleteAdvertisementResponse
} = appAdvertisementSlice.actions

export default appAdvertisementSlice.reducer