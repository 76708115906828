// API CONSTANTS

export const ENV = 'prod'

export const HOST = ENV === 'prod' ? 'https://synergy.capternal.com' : 'http://15.207.182.121'
// export const HOST = 'http://15.207.182.121'
// export const HOST = 'http://localhost:4555'

export const API = '/api/advertisement/admin'
export const LOGIN = `${HOST}${API}/user/login`
//Devices
export const DEVICES_LIST = `${HOST}${API}/device/list`
export const DEVICES_ADD = `${HOST}${API}/device/add`
export const DEVICE_DETAILS = `${HOST}${API}/device/details`
export const DEVICES_EDIT = `${HOST}${API}/device/edit`
export const DEVICE_DELETE = `${HOST}${API}/device/remove`
export const GET_STORE_LIST = `${HOST}${API}/store/get`

// Advertisement
export const ADVERTISEMENT_LIST = `${HOST}${API}/advertisement/list`
export const ADVERTISEMENT_ADD = `${HOST}${API}/advertisement/add`
export const ADVERTISEMENT_DETAILS = `${HOST}${API}/advertisement/details`
export const ADVERTISEMENT_EDIT = `${HOST}${API}/advertisement/edit`
export const ADVERTISEMENT_DELETE = `${HOST}${API}/advertisement/remove`

// Categories
export const CATEGORIES_LIST = `${HOST}${API}/category/list`
export const CATEGORIES_ADD = `${HOST}${API}/category/add`
export const CATEGORIES_DETAILS = `${HOST}${API}/category/details`
export const CATEGORIES_EDIT = `${HOST}${API}/category/edit`
export const CATEGORIES_DELETE = `${HOST}${API}/category/remove`
export const CATEGORY_AND_ADVERTISEMENT = `${HOST}${API}/category/category-list`
export const CATEGORY_WISE_ADVERTISEMENT = `${HOST}${API}/category/category-wise-advertisements`
export const ADVERTISEMENT_WITHOUT_CATEGORIES = `${HOST}${API}/advertisement/no-category`

//Location Master
export const LOCATION_MASTER_LIST = `${HOST}${API}/location/list`
export const LOCATION_MASTER_ADD = `${HOST}${API}/location/add`
export const LOCATION_MASTER_DETAILS = `${HOST}${API}/location/details`
export const LOCATION_MASTER_EDIT = `${HOST}${API}/location/edit`
export const LOCATION_MASTER_DELETE = `${HOST}${API}/location/remove`

//Region
export const REGION_ADD = `${HOST}${API}/region/add`
export const REGION_EDIT = `${HOST}${API}/region/edit`
export const REGION_DETAILS = `${HOST}${API}/region/details`
export const REGION_LIST = `${HOST}${API}/region/list`
export const REGION_DELETE = `${HOST}${API}/region/remove`

//Store
export const STORE_ADD = `${HOST}${API}/store/add`
export const STORE_EDIT = `${HOST}${API}/store/edit`
export const STORE_DETAILS = `${HOST}${API}/store/detail`
export const STORE_LIST = `${HOST}${API}/store/list`
export const STORE_DELETE = `${HOST}${API}/store/remove`

//Playlist
export const PLAYLIST_ADD = `${HOST}${API}/playlist/add`
export const PLAYLIST_EDIT = `${HOST}${API}/playlist/edit`
export const PLAYLIST_LIST = `${HOST}${API}/playlist/list`
export const PLAYLIST_DETAILS = `${HOST}${API}/playlist/detail`
export const PLAYLIST_ADVERTISEMENT = `${HOST}${API}/playlist-advertisement/get`
export const DELETE_PLAYLIST_ADVERTISEMENT = `${HOST}${API}/playlist-advertisement/delete`
export const PLAYLIST_ADVERTISEMENT_ADD = `${HOST}${API}/playlist-advertisement/add`
export const DEVICE_PLAYLIST = `${HOST}${API}/device-playlist/list`
export const DEVICE_PLAYLIST_ADD = `${HOST}${API}/device-playlist/add`
export const DEVICE_PLAYLIST_DELETE = `${HOST}${API}/device-playlist/remove`

//Dashboard
export const DASHBOARD_LIST = `${HOST}${API}/dashboard/list`

export const isDataModeOn = false