import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { DEVICES_ADD, DEVICES_EDIT, DEVICES_LIST, DEVICE_DELETE, DEVICE_DETAILS, GET_STORE_LIST } from "../../constant"
import { post } from "../../api"

//List
export const actionDevices = createAsyncThunk('appDeviceSlice/actionDevices', async (params, thunkAPI) => {
    return await post(DEVICES_LIST, params, thunkAPI)
})
// //Add
export const actionAddDevices = createAsyncThunk('appDeviceSlice/actionAddDevices', async (params, thunkAPI) => {
    return await post(DEVICES_ADD, params, thunkAPI)
})
//Details
export const actionGetDevice = createAsyncThunk('appDeviceSlice/actionGetDevice', async (params, thunkAPI) => {
    return await post(DEVICE_DETAILS, params, thunkAPI)
})
//Edit
export const actionEditDevices = createAsyncThunk('appDeviceSlice/actionEditDevices', async (params, thunkAPI) => {
    return await post(DEVICES_EDIT, params, thunkAPI)
})
//Delete
export const actionDeleteDevice = createAsyncThunk('appDeviceSlice/actionDeleteDevice', async (params, thunkAPI) => {
    return await post(DEVICE_DELETE, params, thunkAPI)
})

//Store List
export const actionGetStoreDevice = createAsyncThunk('appDeviceSlice/actionGetStoreDevice', async (params, thunkAPI) => {
    return await post(GET_STORE_LIST, params, thunkAPI)
})

export const appDeviceSlice = createSlice({
    name: 'appDeviceSlice',
    initialState: {
        loadingDevice: false,
        device: null,

        loadingAddDevice: false,
        addDevice: null,

        loadingGetDevice: false,
        getDevice: null,

        loadingEditDevice: false,
        editDevice: null,

        loadingDeleteDevice: false,
        deviceDelete: null,

        loadingGetStoreList: false,
        getStoreList: null
    },
    reducers: {
        resetDeviceResponse: (state) => {
            state.device = null
        },

        resetAddDeviceResponse: (state) => {
            state.addDevice = null
        },
        resetGetDeviceResponse: (state) => {
            state.getDevice = null
        },

        resetEditDeviceResponse: (state) => {
            state.editDevice = null
        },

        resetDeleteDeviceResponse: (state) => {
            state.deviceDelete = null
        },
        resetGetStoreListResponse: (state) => {
            state.getStoreList = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionDevices.pending, (state) => {
                state.loadingDevice = true
            })

            .addCase(actionDevices.fulfilled, (state, action) => {
                state.loadingDevice = false
                state.device = action.payload
            })
            .addCase(actionDevices.rejected, (state) => {
                state.loadingDevice = false
            })

            //Add
            .addCase(actionAddDevices.pending, (state) => {
                state.loadingAddDevice = true
            })
            .addCase(actionAddDevices.fulfilled, (state, action) => {
                state.loadingAddDevice = false
                state.addDevice = action.payload
            })
            .addCase(actionAddDevices.rejected, (state) => {
                state.loadingAddDevice = false
            })

            // Details
            .addCase(actionGetDevice.pending, (state) => {
                state.loadingGetDevice = true
            })
            .addCase(actionGetDevice.fulfilled, (state, action) => {
                state.loadingGetDevice = false
                state.getDevice = action.payload
            })
            .addCase(actionGetDevice.rejected, (state) => {
                state.loadingGetDevice = false
            })

            // Edit
            .addCase(actionEditDevices.pending, (state) => {
                state.loadingEditDevice = true
            })
            .addCase(actionEditDevices.fulfilled, (state, action) => {
                state.loadingEditDevice = false
                state.editDevice = action.payload
            })
            .addCase(actionEditDevices.rejected, (state) => {
                state.loadingEditDevice = false
            })

            //Delete
            .addCase(actionDeleteDevice.pending, (state) => {
                state.loadingDeleteDevice = true
            })
            .addCase(actionDeleteDevice.fulfilled, (state, action) => {
                state.loadingDeleteDevice = false
                state.deviceDelete = action.payload
            })
            .addCase(actionDeleteDevice.rejected, (state) => {
                state.loadingDeleteDevice = false
            })

            //GetStoreList
            .addCase(actionGetStoreDevice.pending, (state) => {
                state.loadingGetStoreList = true
            })
            .addCase(actionGetStoreDevice.fulfilled, (state, action) => {
                state.loadingGetStoreList = false
                state.getStoreList = action.payload
            })
            .addCase(actionGetStoreDevice.rejected, (state) => {
                state.loadingGetStoreList = false
            })
    }
})
export const { resetDeviceResponse,
    resetAddDeviceResponse,
    resetGetDeviceResponse,
    resetEditDeviceResponse,
    resetDeleteDeviceResponse,
    resetGetStoreListResponse
} = appDeviceSlice.actions

export default appDeviceSlice.reducer