import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PLAYLIST_ADD, PLAYLIST_LIST, PLAYLIST_ADVERTISEMENT, DELETE_PLAYLIST_ADVERTISEMENT, PLAYLIST_ADVERTISEMENT_ADD, DEVICE_PLAYLIST, DEVICE_PLAYLIST_ADD, DEVICE_PLAYLIST_DELETE, PLAYLIST_EDIT, PLAYLIST_DETAILS } from '../../constant'
import { post } from '../../api'

//** import apis

export const defaultPlaylistConfig = {
    "playlist": {
        "section": "playlist",
        "content": {
            "playlist_id": null
        },
        "options": {
            "section": "assets",
            "filter": null,
            "render_type": "with_category",
            "render_type_value": null
        }
    },
    "screens": {
        "section": "screen",
        "content": {
            "screen_id": null
        },
        "options": {
            "section": "playlist",
            "filter": null,
            "render_type": null,
            "render_type_value": null
        }
    }
}

// config
export const actionPlaylistConfig = createAsyncThunk('appPLaylistSLice/actionPlaylistConfig', async (params) => {
    return params
})

//List
export const actionPlaylist = createAsyncThunk('appPlaylist/actionPlaylist', async (params, thunkAPI) => {
    return await post(PLAYLIST_LIST, params, thunkAPI)
})

// //Add
export const actionAddPlaylist = createAsyncThunk('appPlaylist/actionAddPlaylist', async (params, thunkAPI) => {
    return await post(PLAYLIST_ADD, params, thunkAPI)
})

//Edit
export const actionEditPlayList = createAsyncThunk('appPlaylist/actionEditPlayList', async (params, thunkAPI) => {
    return await post(PLAYLIST_EDIT, params, thunkAPI)
})

//Details
export const actionDetailPlaylist = createAsyncThunk('appPlaylist/actionDetailPlaylist', async (params, thunkAPI) => {
    return await post(PLAYLIST_DETAILS, params, thunkAPI)
})

// PlayListAdvertisement API
export const actionPlayListAdvertisement = createAsyncThunk('appPlaylist/actionPlayListAdvertisement', async (params, thunkAPI) => {
    return await post(PLAYLIST_ADVERTISEMENT, params, thunkAPI)
})

// delete Playlist Advertisement
export const actionDeletePlaylistAdvertisement = createAsyncThunk('appPlaylist/actionDeletePlaylistAdvertisement', async (params, thunkAPI) => {
    return await post(DELETE_PLAYLIST_ADVERTISEMENT, params, thunkAPI)
})

// Playlist Advertisement Add
export const actionPlaylistAdvertisementAdd = createAsyncThunk('appPlaylist/actionPlaylistAdvertisementAdd', async (params, thunkAPI) => {
    return await post(PLAYLIST_ADVERTISEMENT_ADD, params, thunkAPI)
})

// Playlist Advertisement Add
export const actionDevicePlaylist = createAsyncThunk('appPlaylist/actionDevicePlaylist', async (params, thunkAPI) => {
    return await post(DEVICE_PLAYLIST, params, thunkAPI)
})

// Device Playlist Add
export const actionDevicePlaylistAdd = createAsyncThunk('appPlaylist/actionDevicePlaylistAdd', async (params, thunkAPI) => {
    return await post(DEVICE_PLAYLIST_ADD, params, thunkAPI)
})

// Device Playlist Delete
export const actionDevicePlaylistDelete = createAsyncThunk('appPlaylist/actionDevicePlaylistDelete', async (params, thunkAPI) => {
    return await post(DEVICE_PLAYLIST_DELETE, params, thunkAPI)
})

export const appPlaylistSlice = createSlice({
    name: 'appPlaylist',
    initialState: {
        //config
        loadingPlaylistConfig: false,
        playlistConfig: defaultPlaylistConfig.playlist,

        //playlist 
        loadingPlaylist: false,
        playlist: null,

        // add play list
        loadingAddPlaylist: false,
        addPlaylist: null,

         // edit play list
         loadingEditPlaylist: false,
         editPlaylist: null,

         //Detail Play list
         loadingDetailPlaylist:false,
         detailPlaylist:null,

        // Play list advertisement
        loadingPlayListAdvertisement: false,
        playListAdvertisement: null,

        // Play list advertisement
        loadingPlayListAdvertisementDelete: false,
        DeletePlayListAdvertisement: null,

        // Play list advertisement
        loadingPlayListAdvertisementAdd: false,
        playListAdvertisementAdd: null,

        // Device Playlist
        loadingDevicePlaylist: false,
        devicePlaylist: null,

        // Device Playlist Add
        loadingDevicePlaylistAdd: false,
        devicePlaylistAdd: null,

        // Device Playlist Delete
        loadingDevicePlaylistDelete: false,
        devicePlaylistDelete: null,
    },
    reducers: {

        //reset config
        resetPlaylistConfig: (state) => {
            state.playlistConfig = defaultPlaylistConfig.playlist
        },

        //reset or delete Add store data
        resetPlaylistResponse: (state) => {
            state.playlist = null
        },

        resetAddPlaylistResponse: (state) => {
            state.addPlaylist = null
        },

        resetEditPlaylistResponse: (state) => {
            state.editPlaylist = null
        },

        resetDetailPlaylistResponse: (state) => {
            state.detailPlaylist = null
        },


        resetPlaylistAdvertisementResponse: (state) => {
            state.playListAdvertisement = null
        },

        resetDeletePlaylistAdvertisementResponse: (state) => {
            state.DeletePlayListAdvertisement = null
        },

        resetPlayAdvertisementAdd: (state) => {
            state.playListAdvertisementAdd = null
        },

        resetDevicePlaylist: (state) => {
            state.devicePlaylist = null
        },

        resetDevicePlaylistAdd: (state) => {
            state.devicePlaylistAdd = null
        },

        resetDevicePlaylistDelete: (state) => {
            state.devicePlaylistDelete = null
        }
    },
    extraReducers: builder => {
        builder

            // Playlist Config
            .addCase(actionPlaylistConfig.pending, (state) => {
                state.loadingPlaylistConfig = true
            })
            .addCase(actionPlaylistConfig.fulfilled, (state, action) => {
                state.loadingPlaylistConfig = false
                state.playlistConfig = action.payload
            })
            .addCase(actionPlaylistConfig.rejected, (state) => {
                state.loadingPlaylistConfig = false
            })

            //List
            .addCase(actionPlaylist.pending, (state) => {
                state.loadingPlaylist = true
            })

            .addCase(actionPlaylist.fulfilled, (state, action) => {
                state.loadingPlaylist = false
                state.playlist = action.payload
            })
            .addCase(actionPlaylist.rejected, (state) => {
                state.loadingPlaylist = false
            })

            //Add
            .addCase(actionAddPlaylist.pending, (state) => {
                state.loadingAddPlaylist = true
            })
            .addCase(actionAddPlaylist.fulfilled, (state, action) => {
                state.loadingAddPlaylist = false
                state.addPlaylist = action.payload
            })
            .addCase(actionAddPlaylist.rejected, (state) => {
                state.loadingAddPlaylist = false
            })

            //Edit
            .addCase(actionEditPlayList.pending, (state) => {
                state.loadingEditPlaylist = true
            })
            .addCase(actionEditPlayList.fulfilled, (state, action) => {
                state.loadingEditPlaylist = false
                state.editPlaylist = action.payload
            })
            .addCase(actionEditPlayList.rejected, (state) => {
                state.loadingEditPlaylist = false
            })

            //Details
            .addCase(actionDetailPlaylist.pending, (state) => {
                state.loadingDetailPlaylist = true
            })
            .addCase(actionDetailPlaylist.fulfilled, (state, action) => {
                state.loadingDetailPlaylist = false
                state.detailPlaylist = action.payload
            })
            .addCase(actionDetailPlaylist.rejected, (state) => {
                state.loadingDetailPlaylist = false
            })

            //list playlist advertisement
            .addCase(actionPlayListAdvertisement.pending, (state) => {
                state.loadingPlayListAdvertisement = true
            })
            .addCase(actionPlayListAdvertisement.fulfilled, (state, action) => {
                state.loadingPlayListAdvertisement = false
                state.playListAdvertisement = action.payload
            })
            .addCase(actionPlayListAdvertisement.rejected, (state) => {
                state.loadingPlayListAdvertisement = false
            })

            //Delete playlist advertisement
            .addCase(actionDeletePlaylistAdvertisement.pending, (state) => {
                state.loadingPlayListAdvertisementDelete = true
            })
            .addCase(actionDeletePlaylistAdvertisement.fulfilled, (state, action) => {
                state.loadingPlayListAdvertisementDelete = false
                state.DeletePlayListAdvertisement = action.payload
            })
            .addCase(actionDeletePlaylistAdvertisement.rejected, (state) => {
                state.loadingPlayListAdvertisementDelete = false
            })

            //playlist advertisement add
            .addCase(actionPlaylistAdvertisementAdd.pending, (state) => {
                state.loadingPlayListAdvertisementAdd = true
            })
            .addCase(actionPlaylistAdvertisementAdd.fulfilled, (state, action) => {
                state.loadingPlayListAdvertisementAdd = false
                state.playListAdvertisementAdd = action.payload
            })
            .addCase(actionPlaylistAdvertisementAdd.rejected, (state) => {
                state.loadingPlayListAdvertisementAdd = false
            })

            // Device Playlist
            .addCase(actionDevicePlaylist.pending, (state) => {
                state.loadingDevicePlaylist = true
            })
            .addCase(actionDevicePlaylist.fulfilled, (state, action) => {
                state.loadingDevicePlaylist = false
                state.devicePlaylist = action.payload
            })
            .addCase(actionDevicePlaylist.rejected, (state) => {
                state.loadingDevicePlaylist = false
            })

            // Device Playlist Add
            .addCase(actionDevicePlaylistAdd.pending, (state) => {
                state.loadingDevicePlaylistAdd = true
            })
            .addCase(actionDevicePlaylistAdd.fulfilled, (state, action) => {
                state.loadingDevicePlaylistAdd = false
                state.devicePlaylistAdd = action.payload
            })
            .addCase(actionDevicePlaylistAdd.rejected, (state) => {
                state.loadingDevicePlaylistAdd = false
            })

            // Device Playlist Delete
            .addCase(actionDevicePlaylistDelete.pending, (state) => {
                state.loadingDevicePlaylistDelete = true
            })
            .addCase(actionDevicePlaylistDelete.fulfilled, (state, action) => {
                state.loadingDevicePlaylistDelete = false
                state.devicePlaylistDelete = action.payload
            })
            .addCase(actionDevicePlaylistDelete.rejected, (state) => {
                state.loadingDevicePlaylistDelete = false
            })
    }
})
export const {
    resetPlaylistResponse, resetAddPlaylistResponse,resetEditPlaylistResponse,resetDetailPlaylistResponse, resetPlaylistAdvertisementResponse, resetDeletePlaylistAdvertisementResponse,
    resetPlaylistConfig, resetPlayAdvertisementAdd, resetDevicePlaylist, resetDevicePlaylistAdd, resetDevicePlaylistDelete
} = appPlaylistSlice.actions

export default appPlaylistSlice.reducer