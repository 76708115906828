// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import appDeviceSlice from '../common/store/device'
import appAdvertisementSlice from '../common/store/advertisement'
import appCategoriesSlice from '../common/store/categories'
import appLocationMasterSlice from '../common/store/location_master'
import appRegionSlice from '../common/store/region'
import appStoreSlice from '../common/store/store'
import appPlaylist from '../common/store/playlist'
import appDashboardSlice from '../common/store/dashboard'

const rootReducer = {
  auth,
  navbar,
  layout,
  appDeviceSlice,
  appAdvertisementSlice,
  appCategoriesSlice,
  appLocationMasterSlice,
  appRegionSlice,
  appStoreSlice,
  appPlaylist,
  appDashboardSlice
}

export default rootReducer
