export const TOAST_DURATION = 4000
export const TOAST_POSITION = 'bottom-right'
export const API_TIMEOUT = 5000
export const AGE_MIN_LIMIT = 18
export const AGE_MAX_LIMIT = 100
export const UNAUTHORIZED = 400
export const ERROR = 402
export const TWO_MB = 2097152   //2 mb in bytes
export const TEN_MB = 10485760   //10 mb in bytes
export const TEN_MB_PLUS_ONE = 10485761   //10 mb in bytes + 1 byte
export const TWENTY_FIVE_MB = 26214400   //25 mb in bytes
export const TWENTY_FIVE_MB_PLUS_ONE = 26214401   //25 mb in bytes

export const FORM_ELEMENT_TYPES = {
    TEXT: 'text',
    SELECT: 'select',
    NESTED: 'nested'
}

export const btnOutline = {
    color: 'secondary',
    className: 'me-1',
    outline: true
}