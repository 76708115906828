import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post } from "../../api"
import { STORE_ADD, STORE_DELETE, STORE_DETAILS, STORE_EDIT, STORE_LIST } from "../../constant"

//List
export const actionStore = createAsyncThunk('appStoreSlice/actionStore', async (params, thunkAPI) => {
    return await post(STORE_LIST, params, thunkAPI)
})
// //Add
export const actionAddStore = createAsyncThunk('appStoreSlice/actionAddStore', async (params, thunkAPI) => {
    return await post(STORE_ADD, params, thunkAPI)
})
//Details
export const actionGetStore = createAsyncThunk('appStoreSlice/actionGetStore', async (params, thunkAPI) => {
    return await post(STORE_DETAILS, params, thunkAPI)
})
//Edit
export const actionEditStore = createAsyncThunk('appStoreSlice/actionEditStore', async (params, thunkAPI) => {
    return await post(STORE_EDIT, params, thunkAPI)
})
// //Delete
export const actionDeleteStore = createAsyncThunk('appStoreSlice/actionDeleteStore', async (params, thunkAPI) => {
    return await post(STORE_DELETE, params, thunkAPI)
})
export const appStoreSlice = createSlice({
    name: 'appStoreSlice',
    initialState: {
        loadingStore: false,
        store: null,

        loadingAddStore: false,
        addStore: null,

        loadingGetStore: false,
        getStore: null,

        loadingEditStore: false,
        editStore: null,

        loadingDeleteStore: false,
        deleteStore: null
    },
    reducers: {
        resetStoreResponse: (state) => {
            state.store = null
        },

        resetAddStoreResponse: (state) => {
            state.addStore = null
        },
        resetGetStoreResponse: (state) => {
            state.getStore = null
        },

        resetEditStoreResponse: (state) => {
            state.editStore = null
        },

        resetDeleteStoreResponse: (state) => {
            state.deleteStore = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionStore.pending, (state) => {
                state.loadingStore = true
            })

            .addCase(actionStore.fulfilled, (state, action) => {
                state.loadingStore = false
                state.store = action.payload
            })
            .addCase(actionStore.rejected, (state) => {
                state.loadingStore = false
            })

            //Add
            .addCase(actionAddStore.pending, (state) => {
                state.loadingAddStore = true
            })
            .addCase(actionAddStore.fulfilled, (state, action) => {
                state.loadingAddStore = false
                state.addStore = action.payload
            })
            .addCase(actionAddStore.rejected, (state) => {
                state.loadingAddStore = false
            })

            // Details
            .addCase(actionGetStore.pending, (state) => {
                state.loadingGetStore = true
            })
            .addCase(actionGetStore.fulfilled, (state, action) => {
                state.loadingGetStore = false
                state.getStore = action.payload
            })
            .addCase(actionGetStore.rejected, (state) => {
                state.loadingGetStore = false
            })

            // Edit
            .addCase(actionEditStore.pending, (state) => {
                state.loadingEditStore = true
            })
            .addCase(actionEditStore.fulfilled, (state, action) => {
                state.loadingEditStore = false
                state.editStore = action.payload
            })
            .addCase(actionEditStore.rejected, (state) => {
                state.loadingEditStore = false
            })

            //Delete
            .addCase(actionDeleteStore.pending, (state) => {
                state.loadingDeleteStore = true
            })
            .addCase(actionDeleteStore.fulfilled, (state, action) => {
                state.loadingDeleteStore = false
                state.deleteStore = action.payload
            })
            .addCase(actionDeleteStore.rejected, (state) => {
                state.loadingDeleteStore = false
            })

    }
})
export const { resetStoreResponse,
    resetAddStoreResponse,
    resetGetStoreResponse,
    resetEditStoreResponse,
    resetDeleteStoreResponse
} = appStoreSlice.actions

export default appStoreSlice.reducer