import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post } from "../../api"
import { DASHBOARD_LIST } from "../../constant"


//List
export const actionDashboard = createAsyncThunk('appDashboardSlice/actionDashboard', async (params, thunkAPI) => {
    return await post(DASHBOARD_LIST, params, thunkAPI)
})

export const appDashboardSlice = createSlice({
    name: 'appDashboardSlice',
    initialState: {
        loadingDashboard: false,
        dashboard: null,
    },
    reducers: {
        resetDashboardResponse: (state) => {
            state.dashboard = null
        },
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionDashboard.pending, (state) => {
                state.loadingDashboard = true
            })

            .addCase(actionDashboard.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.dashboard = action.payload
            })
            .addCase(actionDashboard.rejected, (state) => {
                state.loadingDashboard = false
            })
    }
})
export const { resetDashboardResponse,
} = appDashboardSlice.actions

export default appDashboardSlice.reducer